<template>
  <div>
    <DataTable
      :value="items"
      :row-hover="true"
      :loading="loading"
      :paginator="true"
      :rows="10"
      :scrollable="true"
      :rowsPerPageOptions="[10, 25, 50]"
      class="p-datatable-sm"
      dataKey="id"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column field="nama_obat" header="Obat" style="min-width: 10rem" />
      <Column field="quantity" header="QTY" style="width: 3rem" />
      <Column field="satuan" header="Satuan" style="width: 3rem" />
      <Column
        field="quantity_tkcl"
        header="QTY terkecil"
        style="min-width: 3rem"
      />
      <Column
        field="satuan_tkcl"
        header="Satuan terkecil"
        style="min-width: 3rem"
      />
      <Column field="harga" header="Harga" style="min-width: 3rem">
        <template #body="{ data }">
          {{ formatCurrency(data.harga) }}
        </template>
      </Column>
      <Column field="diskon" header="Diskon (%)" style="min-width: 100px" />
      <Column
        field="harga_diskon_display"
        header="Diskon (Rp)"
        style="min-width: 150px"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.harga_diskon_display) }}
        </template>
      </Column>
      <Column field="dpp" header="DPP" style="min-width: 150px">
        <template #body="{ data }">
          {{ formatCurrency(data.dpp) }}
        </template>
      </Column>
      <Column field="harga_total" header="Jumlah" style="min-width: 200px">
        <template #body="{ data }">
          {{ formatCurrency(data.harga_total) }}
        </template>
      </Column>
      <Column field="keterangan" header="Keterangan" style="min-width: 200px">
        <template #body="{ data }">
          {{ data.keterangan }}
        </template>
      </Column>
      <Column>
        <template #body="{ data }">
          <div class="flex justify-content-end align-items-end">
            <Button
              :disabled="data.has_invoice === 1 || this.isLoading === true"
              type="button"
              icon="pi pi-pencil"
              class="p-button-outlined p-button-secondary p-button-text mr-2"
              @click="$emit('edit', data)"
            />
            <Button
              :disabled="data.has_invoice === 1"
              type="button"
              icon="pi pi-trash"
              class="p-button-outlined p-button-danger p-button-text"
              @click="$emit('delete', data)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { formatCurrency, formatNumber } from '@/helpers'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: 'draft',
    },
  },
  data() {
    return {}
  },
  methods: {
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatNumber(value) {
      return formatNumber(value)
    },
  },
}
</script>
